
import { Component, Vue } from "vue-property-decorator";
import Publicacion from "@/components/Noticias/PublicacionPreview.vue";

@Component({
  components: {
    Publicacion
  }
})
export default class PublicacionPreviewView extends Vue {}
